<template>
  <div class="fandom-autocomplete form-group">
    <label class="form-label" for="fandom">Fandom {{ number }}</label>
    <span v-if="fandom.name">
      {{ fandom.name }}
      <button class="btn btn-clear" @click="removeFandom"></button>
    </span>

    <template v-if="!fandom.name">
      <input
        :class="['form-input mb-2', { 'is-error': hasError }]"
        type="text"
        placeholder="Search..."
        @keyup="autocomplete"
        @keydown.enter.stop="select"
        @keydown.down="next"
        @keydown.up="prev"
        v-model="term"
      />

      <div v-if="options.length">
        <strong class="mt-2">Available Fandoms</strong>
        <ul>
          <li
            :class="['option c-hand', { focused: i === selectedIndex }]"
            v-for="(option, i) in options"
            :key="option['.key']"
            @mouseenter="selectedIndex = i"
            @mouseleave="selectedIndex = -1"
            @click="select"
            v-html="highlight(option.name, 'f')"
          ></li>
        </ul>
      </div>
    </template>

    <div v-else>
      <label class="form-label" for="chars">Requested Characters</label>
      <div class="badges">
        <span class="character chip" :key="char" v-for="char in chars">
          {{ char }}
          <button class="btn btn-clear" @click="removeChar(char)"></button>
        </span>
        <span class="chip" v-if="!chars.length">Any</span>
      </div>
      <p v-if="charsLoading">Loading character options...</p>
      <div v-else class="nominated-characters">
        <div
          v-if="
            characters[fandom['.key']] &&
            characters[fandom['.key']].length &&
            chars
          "
        >
          <strong>Nominated Characters</strong>
          <ul v-if="chars.length < maxChars">
            <li
              :class="[
                'option c-hand',
                {
                  focused: i === selectedIndex,
                  disabled: chars.length == maxChars,
                },
              ]"
              v-for="(option, i) in options"
              :key="option"
              v-html="option"
              @mouseenter="selectedIndex = i"
              @mouseleave="selectedIndex = -1"
              @click="select('char')"
            ></li>
          </ul>
          <p v-else>Maximum number of character requests reached</p>
        </div>
        <p v-else>No characters were nominated</p>
      </div>
    </div>

    <p class="msg" v-if="msg">{{ msg }}</p>
  </div>
</template>

<script>
import { latinise } from "voca";
import axios from "axios";
import { filter, difference, toArray } from "lodash-es";
import { mapGetters } from "vuex";
export default {
  props: {
    savedData: {
      type: Object,
      default() {
        return null;
      }
    },
    hasError: {
      type: Boolean,
      default: false
    },
    fandoms: {
      type: Array,
      default() {
        return [];
      }
    },
    maxChars: {
      type: Number,
      default: 4
    },
    number: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      msg: "",
      term: "",
      fandom: {},
      chars: [],
      options: [],
      selectedIndex: -1,
      charsLoading: false
    };
  },
  computed: {
    ...mapGetters({ characters: "characters", allFandoms: "fandoms" })
  },
  watch: {
    term(val, oldVal) {
      if (val !== oldVal) {
        this.selectedIndex = -1;
        this.msg = null;
      }
    },
    chars: {
      deep: true,
      handler() {
        this.update();
      }
    },
    fandom: {
      deep: true,
      handler() {
        this.update();
      }
    },
    savedData: {
      deep: true,
      handler() {
        this.loadSaved();
      }
    }
  },
  beforeMount() {
    if (this.savedData !== null) {
      this.loadSaved();
    }
  },
  methods: {
    loadSaved() {
      this.chars = this.savedData.characters || [];
      this.fandom = this.allFandoms[this.savedData.key];
      this.autocomplete();
    },
    update() {
      this.$emit("update", {
        fandom: {
          name: this.fandom.name,
          ".key": this.fandom[".key"]
        },
        characters: this.chars
      });
    },
    autocomplete() {
      if (this.chars.length === this.maxChars) {
        this.msg = "You cannot select any more characters!";
        return;
      }
      if (!this.term.length && !this.fandom.name) {
        this.options = [];
        return;
      }

      if (!this.fandom.name) {
        this.options = filter(this.fandoms, o => {
          return (
            latinise(o.name)
              .toLowerCase()
              .indexOf(this.term.toLowerCase()) > -1
          );
        });
        return;
      } else {
        const fandomKey = this.fandom[".key"];
        const results = filter(this.characters[fandomKey], o => {
          if (!o) {
            o = "";
          }
          return (
            latinise(o)
              .toLowerCase()
              .indexOf(this.term.toLowerCase()) > -1
          );
        });

        this.options = difference(results, this.chars) || [];
      }
    },
    removeFandom() {
      this.fandom = "";
      this.chars = [];
      this.options = [];
      this.selectedIndex = -1;
      this.term = "";
      this.msg = "";
    },
    removeChar(char) {
      this.chars = filter(this.chars, o => {
        return o !== char;
      });

      this.term = ""; // reset the search to be explicit
      this.msg = "";

      this.options = difference(
        toArray(this.characters[this.fandom[".key"]]),
        this.chars
      );
    },
    highlight(option) {
      if (!option) {
        return;
      }
      // highlight any char
      const regex = new RegExp(this.term, "ig");
      return option.replace(regex, "<strong>$&</strong>");
    },
    select(type) {
      if (
        this.selectedIndex < 0 ||
        this.selectedIndex > this.options.length - 1
      ) {
        this.msg = "You must select from the available options!";
        return;
      }

      if (type !== "char") {
        this.show = null;
        this.fandom = this.options[this.selectedIndex];
        this.term = "";
        const fandomKey = this.fandom[".key"];
        this.options = [];

        if (!this.characters[fandomKey]) {
          this.charsLoading = true;

          axios
            .get(
              `https://tagset2023-default-rtdb.firebaseio.com/characters/${fandomKey}.json`
            )
            .then(res => {
              this.$store.commit("addChar", {
                key: fandomKey,
                result: res.data
              });
              this.options = res.data || [];
              this.charsLoading = false;
            });
        } else {
          this.options = this.characters[fandomKey] || [];
        }
        return;
      }

      this.chars.push(this.options[this.selectedIndex]);
      this.term = "";
      this.options = difference(
        toArray(this.characters[this.fandom[".key"]]),
        this.chars
      );
    },
    next() {
      if (this.selectedIndex === this.options.length - 1) {
        return;
      }
      this.selectedIndex++;
    },
    prev() {
      if (this.selectedIndex <= 0) {
        this.selectedIndex = -1;
      }
      this.selectedIndex--;
    }
  }
};
</script>

<style lang="scss" scoped>
label,
.focused {
  font-weight: bold;
}

option {
  padding: 3px 0;
  &:disabled:hover {
    font-weight: normal;
    cursor: not-allowed;
  }
  &:hover {
    font-weight: bold;
  }
}

.nominated-characters {
  margin-top: 1em;
}

ul {
  list-style-type: none;
  margin: 0;
}
</style>
