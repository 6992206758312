<template>
  <div>
    <h2>Letters</h2>
    <p>
      Traditionally, participants in Yuletide may voluntarily submit their
      public Dear Author letters to 'tide everyone through until all requests
      are made public after assignments go out.
    </p>
    <p>
      Submitting your letter to the app does
      <strong>not count as signing up for Yuletide!</strong> Use the link to the
      AO3 challenge at the top of the page to sign up once sign ups open.
    </p>
    <button
      :class="['btn mr-2 mb-2', { 'btn-primary': view == 'add' }]"
      @click="view = 'add'"
      :disabled="view === 'add'"
    >
      Add Your Letter
    </button>
    <button
      :class="['btn mb-2', { 'btn-primary': view == 'edit' }]"
      @click="view = 'edit'"
      :disabled="view === 'edit'"
    >
      Edit Your Letter
    </button>
    <add-letter v-if="view == 'add'"></add-letter>
    <edit-letter v-if="view == 'edit'"></edit-letter>
  </div>
</template>

<script>
import AddLetter from "../components/add-letter.vue";
import EditLetter from "../components/edit-letter.vue";
export default {
  components: {
    AddLetter,
    EditLetter
  },
  data() {
    return {
      view: ""
    };
  }
};
</script>

<style lang="scss" scoped></style>
