<template>
  <div>
    <h3>Edit Your Letter</h3>

    <div class="creds" v-if="!unlocked">
      <p>
        If you can't remember your key, ping a mod on Discord or contact them on
        DW/LJ.
      </p>
      <form>
        <label class="form-label" for="u">Username:</label>
        <input
          class="form-input"
          type="text"
          id="u"
          placeholder="Username"
          v-model="username"
          autocomplete="username"
        />
        <label class="form-label" for="key">Letter Key:</label>
        <input
          class="form-input"
          type="password"
          placeholder="Your letter key..."
          v-model="userKey"
          autocomplete="current-password"
          id="key"
          @keydown.enter="loadUser"
        />
      </form>

      <div class="error" v-if="error.length">{{ error }}</div>

      <button @click="loadUser" class="btn btn-secondary">Let Me Edit!</button>
    </div>

    <template v-else-if="!success && !deleted">
      <div v-if="!isReview && !userMeta.isPinchhitter">
        <div
          :class="[
            'input username',
            { error: hasError('username') || hasError('usernameExists') },
          ]"
        >
          <label for="username">AO3 Username:</label>
          <input
            v-focus
            id="username"
            type="text"
            v-model="username"
            placeholder="AO3 Username"
          />
        </div>

        <div :class="['input link', , { error: hasError('url') }]">
          <label for="letter">Letter Link:</label>
          <input
            type="text"
            id="letter"
            v-model="url"
            placeholder="Letter link - must be public!"
          />
        </div>

        <div>
          <fandom-autocomplete
            :hasError="hasError('fandom')"
            v-for="i in max"
            :number="i"
            :fandoms="availableFandoms"
            :key="i"
            @update="update(i - 1, $event)"
            :saved-data="userData[Object.keys(userData)[i - 1]]"
          ></fandom-autocomplete>
        </div>

        <div class="form-group" v-if="isModIn && unlock">
          <label class="form-checkbox form-inline">
            <input type="checkbox" v-model="pinchhitter" />
            <i class="form-icon"></i> Pinch hitter
          </label>
        </div>
      </div>

      <template v-else-if="isReview && !userMeta.isPinchhitter">
        <table class="table">
          <tbody>
            <tr>
              <th>Username</th>
              <td>{{ username }}</td>
            </tr>
            <tr>
              <th>Letter Link</th>
              <td>{{ url }}</td>
            </tr>
            <tr
              v-for="(fandom, i) in scrubbedFandoms"
              :key="fandom.fandom.name"
            >
              <th>Fandom {{ i + 1 }}</th>
              <td>{{ fandom.fandom.name }}</td>
              <td>
                <ul class="chars">
                  <li v-if="!fandom.characters.length">Any</li>
                  <li v-for="char in fandom.characters" :key="char">
                    {{ char }}
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </template>

      <p v-else>
        Pinch hitter prompts can't be edited from the app, please talk to K
      </p>

      <div class="error list" v-if="errors.length">
        <p>Uh oh, looks like you need to fix some things...</p>
        <ul>
          <li v-if="hasError('usernameExists')">
            You tried changing your username to one that already exists
          </li>
          <li v-if="hasError('username')">You need a username</li>
          <li v-if="hasError('url')">
            You need a valid letter link (including the starting http!)
          </li>
          <li v-if="hasError('fandom')">You need at least {{ min }} fandoms</li>
        </ul>
      </div>

      <button
        @click="submit"
        :class="['btn mr-2', { 'btn-primary': isReview }]"
      >
        {{ submitText }}
      </button>
      <button
        v-if="isReview"
        @click="isReview = false"
        :class="['btn', { 'btn-primary': !isReview }]"
      >
        Edit
      </button>
      <button @click="deleteLetter" class="btn btn-error float-right">
        Delete
      </button>

      <p class="notices small">
        Mods will delete any letter that is locked or breaks rules; your AO3
        email will be sent a courtesy notice. You may resubmit a fixed letter at
        any time!
      </p>
    </template>

    <div v-if="success">
      <hr />
      Your letter has been successfully edited!
      <p>
        Here are your requests formatted in HTML so you can easily share them -
        may we suggest posting to the
        <a href="https://yuletide.dreamwidth.org/304485.html" target="blank"
          >Letters Post</a
        >
        for those who track comments there?
      </p>
      <textarea v-model="getCopypasta"></textarea>
    </div>

    <div v-show="deleted">Your letter was successfully deleted.</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FandomAutocomplete from "../components/fandom-autocomplete.vue";
import axios from "axios";
import {
  each,
  compact,
  difference,
  includes,
  filter,
  map,
  identity,
  pickBy
} from "lodash-es";

export default {
  name: "edit-letter",
  components: {
    FandomAutocomplete
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      }
    }
  },
  beforeMount() {
    this.availableFandoms = this.fandoms;
  },
  activated() {
    if (this.success || this.deleted) {
      this.success = false;
      this.deleted = false;
    }
    this.error = "";
  },
  data() {
    return {
      errors: [],
      max: 6,
      min: 3,
      username: "",
      selectedFandoms: [],
      scrubbedFandoms: [],
      url: "",
      isReview: false,
      availableFandoms: [],
      userExists: false,
      userKey: "",
      userData: {},
      userMeta: {},
      unlocked: false,
      error: "",
      success: false,
      deleted: false,
      pinchhitter: false
    };
  },
  computed: {
    ...mapGetters(["fandoms", "characters", "letters", "isModIn", "unlock"]),
    submitText() {
      if (this.isReview) {
        return "Submit!";
      }

      return "Preview!";
    },
    getCopypasta() {
      if (!this.scrubbedFandoms.length) {
        return;
      }

      const pasta = [`<p><strong>${this.username}</strong><br>${this.url}</p>`];

      each(this.scrubbedFandoms, f => {
        const fandom = f.fandom;
        let chars = f.characters;
        if (!chars.length) {
          let allChars = "None nominated";
          if (this.characters[f.fandom[".key"]]) {
            allChars = this.characters[f.fandom[".key"]].join(", ");
          }

          chars = [`Any (${allChars})`];
        }
        const s = `<p><strong>${fandom.name}</strong><br>${chars.join(
          ", "
        )}</p>`;
        pasta.push(s);
      });

      return pasta.join("\n");
    }
  },
  methods: {
    // https://stackoverflow.com/questions/8667070/javascript-regular-expression-to-validate-url
    validateUrl(value) {
      return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
        value
      );
    },
    async deleteLetter() {
      if (!confirm("Are you sure you want to delete your letter?")) {
        return;
      }

      if (!this.username || !this.username.length) {
        return;
      }

      axios.delete(
        `https://tagset2023-default-rtdb.firebaseio.com/letterkeys/${this.username}.json`
      );

      this.scrubFandoms();
      each(this.scrubbedFandoms, req => {
        axios.delete(
          `https://tagset2023-default-rtdb.firebaseio.com/letters/${req.fandom[".key"]}/${this.username}.json`
        );
      });

      await axios
        .get(`https://tagset2023-default-rtdb.firebaseio.com/letters.json`)
        .then(res => {
          this.$store.commit("setLetters", res.data);
        });

      this.deleted = true;
    },
    async submit() {
      this.errors = [];
      this.scrubFandoms();

      if (this.isReview) {
        this.add();
        return;
      }

      if (this.username !== this.userMeta.username) {
        const newUsernameExists = await axios
          .get(
            `https://tagset2023-default-rtdb.firebaseio.com/letterkeys/${this.username}.json`
          )
          .then(res => {
            return res.data ? true : false;
          })
          .catch(() => {
            return false;
          });

        if (newUsernameExists) {
          this.errors.push("usernameExists");
        }
      }

      if (!this.username.length) {
        this.errors.push("username");
      }

      if (!this.url || !this.url.length || !this.validateUrl(this.url)) {
        this.errors.push("url");
      }

      if (this.scrubbedFandoms.length < this.min) {
        this.errors.push("fandom");
      }

      if (this.errors.length) {
        return;
      }

      this.isReview = true;
    },
    scrubFandoms() {
      // put things into a separate array so that index order is preserved
      // if users want to go back and edit
      this.scrubbedFandoms = compact(
        filter(this.selectedFandoms, f => {
          return f !== null && f !== undefined;
        })
      );
    },
    edit() {
      this.isReview = false;
    },
    hasError(type) {
      return includes(this.errors, type);
    },
    update(index, data) {
      let newVal = this.selectedFandoms;

      if (!data.fandom || !data.fandom.name) {
        newVal[index] = null;
      } else {
        newVal[index] = data;
      }

      this.selectedFandoms = [];
      this.selectedFandoms = newVal;

      const selected = [];

      each(this.selectedFandoms, o => {
        if (!o) {
          return;
        }
        selected.push(o.fandom[".key"]);
      });

      this.availableFandoms = filter(this.fandoms, o => {
        return !includes(selected, o[".key"]);
      });
    },
    loadUser() {
      axios
        .get(
          `https://tagset2023-default-rtdb.firebaseio.com/letterkeys/${this.username}.json`
        )
        .then(async res => {
          const user = res.data;
          if (!user) {
            this.error =
              "Could not find a letter for this username. Check case-sensitivity!";
          } else {
            if (user.key !== this.userKey) {
              this.error = "Wrong letter key!";
            } else {
              this.userData = pickBy(user.fandoms, identity);

              this.userMeta = {
                username: this.username,
                key: user.key,
                timestamp: user.timestamp,
                isPinchhitter: user.isPinchhitter
              };

              // load the character lists
              for (let key in user.fandoms) {
                await axios
                  .get(
                    `https://tagset2023-default-rtdb.firebaseio.com/characters/${key}.json`
                  )
                  .then(async res => {
                    const result = res.data;
                    this.$store.commit("addChar", { key: key, result });
                  });
              }
              this.url = this.userData[Object.keys(this.userData)[0]]
                ? this.userData[Object.keys(this.userData)[0]].url
                : "";

              this.unlocked = true;
            }
          }
        });
    },

    async add() {
      if (!this.username || !this.username.length) {
        return;
      }
      // if the username got edited, delete the old one
      // and create a new one that maintains the right metadata
      if (this.username !== this.userMeta.username) {
        axios.delete(
          `https://tagset2023-default-rtdb.firebaseio.com/letterkeys/${this.userMeta.username}.json`
        );

        each(this.userData, f => {
          axios.delete(
            `https://tagset2023-default-rtdb.firebaseio.com/letters/${f.key}/${this.userMeta.username}.json`
          );
        });

        // create the new user
        await axios.put(
          `https://tagset2023-default-rtdb.firebaseio.com/letterkeys/${this.username}.json`,
          {
            key: this.userKey,
            timestamp: this.userMeta.timestamp
          }
        );
      }
      // clean out the user's fandom references
      axios.delete(
        `https://tagset2023-default-rtdb.firebaseio.com/letterkeys/${this.username}/fandoms.json`
      );

      // write any updates
      each(this.scrubbedFandoms, async req => {
        await axios.put(
          `https://tagset2023-default-rtdb.firebaseio.com/letters/${req.fandom[".key"]}/${this.username}.json`,
          {
            username: this.username,
            url: this.url,
            characters: req.characters,
            isPinchhitter: this.pinchhitter || false
          }
        );

        axios.put(
          `https://tagset2023-default-rtdb.firebaseio.com/letterkeys/${this.username}/fandoms/${req.fandom[".key"]}/.json`,
          {
            url: this.url,
            key: req.fandom[".key"],
            characters: req.characters,
            isPinchhitter: this.pinchhitter || false
          }
        );
      });

      // delete any removes
      const newFandoms = map(this.scrubbedFandoms, f => {
        return f.fandom[".key"];
      });
      const oldFandoms = map(this.userData, f => {
        if (!f) {
          return;
        }
        return f.key;
      });
      const deletedFandoms = difference(oldFandoms, newFandoms);

      each(deletedFandoms, index => {
        if (index !== null && index !== undefined) {
          axios.delete(
            `https://tagset2023-default-rtdb.firebaseio.com/letters/${index}/${this.username}.json`
          );
        }
      });

      this.success = true;
      this.showSubmit = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.creds {
  input {
    display: block;
    margin: 10px 0;
  }
}
label {
  font-weight: bold;
  width: 130px;
  display: inline-block;
}

.fandom-autocomplete {
  margin: 10px 0;
  border-top: 1px solid #cfcfcf;
  padding: 10px 0;
}

.error {
  color: red !important;
  padding: 10px 0;
}

.list {
  margin-bottom: 10px;
}

table {
  td,
  th {
    border: 0;
  }
  th {
    vertical-align: top;
    padding: 10px 5px;
  }

  th {
    width: 15%;
  }
}

li {
  &:first-child {
    padding-top: 0;
    margin-top: 0;
  }
  margin-left: 25px;
  list-style-type: square;
}

.notices {
  margin: 10px 0;
}
</style>
