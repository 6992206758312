<template>
  <div>
    <h3>Add Your Letter</h3>
    <div v-show="!isReview">
      <div class="form-group">
        <label class="form-label" for="username">AO3 Username</label>
        <input
          :class="['form-input', { 'is-error': hasError('username') }]"
          type="text"
          id="iusername"
          v-model="username"
          placeholder="AO3 Username"
        />
      </div>
      <div class="form-group">
        <label class="form-label" for="username">
          Letter URL
          <small>Must be public</small>
        </label>
        <input
          :class="['form-input', { 'is-error': hasError('username') }]"
          type="text"
          id="letter"
          v-model="url"
          placeholder="http://..."
        />
      </div>

      <div>
        <fandom-autocomplete
          :hasError="hasError('fandom')"
          v-for="i in max"
          :number="i"
          :fandoms="availableFandoms"
          :key="i"
          @update="update(i - 1, $event)"
        ></fandom-autocomplete>
      </div>

      <div class="form-group" v-if="isModIn && unlock">
        <label class="form-checkbox form-inline">
          <input type="checkbox" v-model="pinchhitter" />
          <i class="form-icon"></i> Pinch hitter
        </label>
      </div>
    </div>

    <template v-if="isReview && !userKey.length">
      <div class="error" v-if="userExists">
        This username has already submitted a letter to the app! Please edit it
        with your key. If you don't remember your key, contact a mod.
      </div>

      <table v-else class="table">
        <tbody>
          <tr>
            <th>Username</th>
            <td>{{ username }}</td>
          </tr>
          <tr>
            <th>Letter Link</th>
            <td>{{ url }}</td>
          </tr>
          <tr v-for="(fandom, i) in scrubbedFandoms" :key="fandom.name">
            <th class="fandom">Fandom {{ i + 1 }}</th>
            <td>{{ fandom.fandom.name }}</td>
            <td>
              <ul class="chars">
                <li v-if="!fandom.characters.length">Any</li>
                <li v-for="char in fandom.characters" :key="char">
                  {{ char }}
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </template>

    <div class="error list" v-if="errors.length">
      <p>Uh oh, looks like you need to fix some things...</p>
      <ul>
        <li v-if="hasError('username')">You need a username</li>
        <li v-if="hasError('url')">
          You need a valid letter link (including the starting http!)
        </li>
        <li v-if="hasError('fandom')">You need at least {{ min }} fandoms</li>
      </ul>
    </div>

    <button @click="submit" v-if="showSubmit" class="submit btn btn-primary">
      {{ submitText }}
    </button>
    <button
      v-if="isReview && showSubmit"
      @click="isReview = false"
      class="submit btn mx-2"
    >
      Edit
    </button>

    <!-- SUCCESS STATE -->
    <div v-show="userKey.length">
      <p>
        Your letter has been successfully submitted! Your letter key is
        <strong>{{ this.userKey }}</strong> - NOTE THIS DOWN. You will need it
        if you want to edit your letter later.
      </p>

      <p>
        Here are your requests formatted in HTML so you can easily share them -
        may we suggest posting to the
        <a href="https://yuletide.dreamwidth.org/304485.html" target="blank"
          >Letters Post</a
        >
        for those who track comments there?
      </p>
      <textarea v-model="getCopypasta"></textarea>

      <p>
        Pssst, your letter is number
        <strong>{{ number }}</strong> to be added!
      </p>

      <hr class="separator" />
    </div>

    <p class="notices small">
      Mods will delete any letter that is locked or breaks rules; your AO3 email
      will be sent a courtesy notice. You may resubmit a fixed letter at any
      time!
    </p>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { compact, each, includes, filter } from "lodash-es";
import FandomAutocomplete from "../components/fandom-autocomplete.vue";
export default {
  components: {
    FandomAutocomplete
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      }
    }
  },
  beforeMount() {
    this.availableFandoms = this.fandoms;
  },
  data() {
    return {
      errors: [],
      max: 6,
      min: 3,
      username: "",
      selectedFandoms: [],
      scrubbedFandoms: [],
      url: "",
      isReview: false,
      availableFandoms: [],
      userExists: false,
      showSubmit: true,
      userKey: "",
      number: undefined,
      pinchhitter: false
    };
  },
  computed: {
    ...mapGetters(["fandoms", "characters", "isModIn", "letters", "unlock"]),
    submitText() {
      if (this.isReview) {
        return "Submit!";
      }

      return "Preview!";
    },
    getCopypasta() {
      if (!this.scrubbedFandoms.length) {
        return;
      }

      const pasta = [`<p><strong>${this.username}</strong><br>${this.url}</p>`];

      each(this.scrubbedFandoms, f => {
        const fandom = f.fandom;
        let chars = f.characters;
        if (!chars || !chars.length) {
          let allChars = "None nominated";
          if (this.characters[f.fandom[".key"]]) {
            allChars = this.characters[f.fandom[".key"]].join(", ");
          }

          chars = [`Any (${allChars})`];
        }
        const s = `<p><strong>${fandom.name}</strong><br>${chars.join(
          ", "
        )}</p>`;

        pasta.push(s);
      });

      return pasta.join("\n");
    }
  },
  methods: {
    // https://stackoverflow.com/questions/8667070/javascript-regular-expression-to-validate-url
    validateUrl(value) {
      return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
        value
      );
    },
    submit() {
      this.errors = [];
      this.scrubFandoms();

      if (this.isReview) {
        if (this.pinchhitter) {
          this.addPinchhitter();
        } else {
          this.add();
        }
        return;
      }

      if (!this.username.length) {
        this.errors.push("username");
      }

      if (!this.url.length || !this.validateUrl(this.url)) {
        this.errors.push("url");
      }

      if (this.scrubbedFandoms.length < this.min) {
        this.errors.push("fandom");
      }

      if (this.errors.length) {
        return;
      }

      this.isReview = true;
    },
    scrubFandoms() {
      // put things into a separate array so that index order is preserved
      // if users want to go back and edit
      this.scrubbedFandoms = compact(
        filter(this.selectedFandoms, f => {
          return f !== null && f !== undefined;
        })
      );
    },
    edit() {
      this.isReview = false;
    },
    hasError(type) {
      return includes(this.errors, type);
    },
    update(index, data) {
      let newVal = this.selectedFandoms;

      if (!data.fandom || !data.fandom.name) {
        newVal[index] = null;
      } else {
        newVal[index] = data;
      }

      this.selectedFandoms = [];
      this.selectedFandoms = newVal;

      const selected = [];

      each(this.selectedFandoms, o => {
        if (!o) {
          return;
        }
        selected.push(o.fandom[".key"]);
      });

      this.availableFandoms = filter(this.fandoms, o => {
        return !includes(selected, o[".key"]);
      });
    },
    async add() {
      axios
        .get(
          `https://tagset2023-default-rtdb.firebaseio.com/letterkeys/${this.username}.json`
        )
        .then(async res => {
          if (!res.data) {
            this.userKey = (Math.random() + 1).toString(36).substring(7);
            this.showSubmit = false;

            // Create the user's key
            axios
              .put(
                `https://tagset2023-default-rtdb.firebaseio.com/letterkeys/${this.username}.json`,
                {
                  key: this.userKey,
                  timestamp: new Date().toISOString()
                }
              )
              .then(async () => {
                each(this.scrubbedFandoms, async req => {
                  // Add letter to each fandom
                  await axios.put(
                    `https://tagset2023-default-rtdb.firebaseio.com/letters/${req.fandom[".key"]}/${this.username}.json`,
                    {
                      username: this.username,
                      url: this.url,
                      characters: req.characters,
                      isPinchhitter: this.pinchhitter || false
                    }
                  );

                  // Add fandom to each letter
                  axios.put(
                    `https://tagset2023-default-rtdb.firebaseio.com/letterkeys/${this.username}/fandoms/${req.fandom[".key"]}/.json`,
                    {
                      url: this.url,
                      key: req.fandom[".key"],
                      characters: req.characters,
                      isPinchhitter: this.pinchhitter || false
                    }
                  );
                });

                await axios
                  .get(
                    `https://tagset2023-default-rtdb.firebaseio.com/letterkeys.json`
                  )
                  .then(res2 => {
                    this.number = Object.keys(res2.data).length;
                  });
              })
              .catch(() => {
                // console.error(
                //   "Problem creating letter for user",
                //   this.username
                // );
              });
          } else {
            this.userExists = true;
            this.showSubmit = false;
          }
        });
    },
    // Add pinch hitter prompts to the prompts db, not just the letters
    async addPinchhitter() {
      axios
        .get(
          `https://tagset2023-default-rtdb.firebaseio.com/letterkeys/${this.username}.json`
        )
        .then(async res => {
          if (!res.data) {
            this.userKey = (Math.random() + 1).toString(36).substring(7);
            this.showSubmit = false;

            // Create the user's key
            axios
              .put(
                `https://tagset2023-default-rtdb.firebaseio.com/letterkeys/${this.username}.json`,
                {
                  key: this.userKey,
                  timestamp: new Date().toISOString()
                }
              )
              .then(async () => {
                each(this.scrubbedFandoms, async req => {
                  // [{"characters":["Emilio Sandoz (The Sparrow Series)"],"fandom":"The Sparrow Series - Mary Doria Russell","letter":"http://tjs-whatnot.dreamwidth.org/250407.html","prompt":"","username":"tjs_whatnot"}]

                  // Add pinch hit prompts to each fandom

                  const reqFandom = await axios.get(
                    `https://tagset2023-default-rtdb.firebaseio.com/prompts/${req.fandom[".key"]}.json`
                  );

                  if (reqFandom.data) {
                    const index = reqFandom.data.length;
                    await axios.put(
                      `https://tagset2023-default-rtdb.firebaseio.com/prompts/${req.fandom[".key"]}/${index}.json`,
                      {
                        username: this.username,
                        letter: this.url,
                        characters: req.characters,
                        prompt:
                          "This user is a volunteer pinch-hitter who is not signed up for Yuletide this year; please see their letter for their prompts and requested characters!",
                        isPinchhitter: true
                      }
                    );
                  } else {
                    await axios.put(
                      `https://tagset2023-default-rtdb.firebaseio.com/prompts/${req.fandom[".key"]}/.json`,
                      [
                        {
                          username: this.username,
                          letter: this.url,
                          characters: req.characters,
                          prompt:
                            "This user is a volunteer pinch-hitter who is not signed up for Yuletide this year; please see their letter for their prompts and requested characters!",
                          isPinchhitter: true
                        }
                      ]
                    );
                  }

                  // Update hasPrompts if this is in a fandom with no official signups
                  var patchData = {};
                  patchData[req.fandom[".key"]] = true;
                  await axios.patch(
                    `https://tagset2023-default-rtdb.firebaseio.com/hasPrompts.json`,
                    patchData
                  );

                  // Add fandom to each letter
                  axios.put(
                    `https://tagset2023-default-rtdb.firebaseio.com/letterkeys/${this.username}/fandoms/${req.fandom[".key"]}/.json`,
                    {
                      url: this.url,
                      key: req.fandom[".key"],
                      characters: req.characters,
                      isPinchhitter: true
                    }
                  );
                });

                await axios
                  .get(
                    `https://tagset2023-default-rtdb.firebaseio.com/letterkeys.json`
                  )
                  .then(res2 => {
                    this.number = Object.keys(res2.data).length;
                  });
              })
              .catch(() => {
                // console.error(
                //   "Problem creating letter for pinchhitter",
                //   this.username
                // );
              });
          } else {
            this.userExists = true;
            this.showSubmit = false;
          }
        });
    }
  }
};
</script>

<style>
.fandom-autocomplete .chip {
  max-width: 100%;
}
</style>

<style lang="scss" scoped>
.fandom-autocomplete {
  margin: 10px 0;
  border-top: 1px solid #cfcfcf;
  padding: 10px 0;
}

table {
  td,
  th {
    border: 0;
  }
  th {
    vertical-align: top;
    padding: 10px 5px;
  }

  th {
    width: 15%;
  }
}

li {
  &:first-child {
    padding-top: 0;
    margin-top: 0;
  }
  margin-left: 25px;
  list-style-type: square;
}

.notices {
  margin: 10px 0;
}
</style>
